import React, { useEffect } from 'react'
import c3 from "c3";
import d3 from "d3";
function CXBarChart(props) {
    const { height } = props
    const keys = [...props.keys]
     const values = [...props.values];
     const dataPoint=[...props.dataPoints];
     //const dummyValues=['801546','661600','701540','701500','961600'];
     console.log("Score page --Keys....",keys);
     console.log("Score page --chartValues....",values)
     console.log("Score page --dataPoints....",dataPoint)
    let csTrendVals = {
        //x: 'x',
       
        columns:
        [
         // ['x', ...keys],
          ['CXScore', ...values],
          ['Data Points',...dataPoint]
        ], 
        axes: {
            CXScore:'y',
            'Data Points': 'y2'
        },

        type: 'bar',
        types:{
            'Data Points':'line'
        },
        labels:  {
            format: {
                CXScore: d3.format()
                //DataPoints: d3.format()
           }
        },   
        
        colors: {
            //CXScore: '#C0C0C0',
            'Data Points': 'black'//,''

        }
        // colors: {
        //     CxScore: 'rgb(155, 211, 222)'
        //     //CxScore: '#ffbb78'
        // },
        // colors: {
        //     CxScore: function(d) {
        //       if(d.value){
        //           var colors = ['#1f77b4', '#aec7e8', '#ff7f0e', '#ffbb78', '#2ca02c', '#98df8a', '#d62728', '#ff9896', '#9467bd', '#c5b0d5', '#8c564b', '#c49c94', '#e377c2', '#f7b6d2', '#7f7f7f', '#c7c7c7', '#bcbd22', '#dbdb8d', '#17becf', '#9edae5'];                    
        //           let colorCode = colors[d.index];
        //          return colorCode;
        //       }
        //   }
        // }
      }
    useEffect(() => {
        var chart = c3.generate({
            bindto: `#cxScoreTrandingChart`,
            size: {
                height: height,
                width: 550
            },
            data: csTrendVals,
            axis: {
                x: {
                    type: 'category',
                    categories: keys,
                    // height: 180
                    tick: {
                        rotate: -50,
                        multiline: false
                    },
                    width: 400,
                },
                y: {
                    label: {
                        text: 'CX Score',
                        position: 'outer-middle'

                    },
                }
                ,y2: {
                    show: true,
                    label: {
                        text: 'Data Points',
                        position: 'outer-middle'
                   
                    },
                    tick:{
                        count:8,
                        format:function (d){
                                return d3.format(",.3r")(d);
                            }
                    }
                 
                }

            },
            tooltip: {
                        grouped: false,
                        format: {
                          value: function (value, ratio, id, index) 
                          { 
                            console.log("Tool Tip----value, ratio, id, index"," ",value," ", ratio," ", id," ",index)
                            return d3.format(",r")(value);
                          }
                        }
            },
            bar: {
                width: {
                    ratio: 0.5 // this makes bar width 50% of length between ticks
                },
                // or
                // width: 100 // this makes bar width 100px
            },
            legend: {
                show: false
            },
            padding: {
                bottom: 60 //adjust chart padding bottom
                // bottom: 20 //adjust chart padding bottom
            }
            // size: {
            //    height:height
            //   }
        });

    })

    return (
        <div id="cxScoreTrandingChart" />
    )

}


export default CXBarChart