import React, { useState, useEffect } from "react";
import styles from "./score.module.scss";
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import GaugeChart from "react-gauge-chart";
import * as utils from "../../utils/utils";
import { Route, Switch } from "react-router-dom";
import CXList from "../../components/cx-list/CXList";
import Tabs from "../../components/tabs/Tabs";
import CAS from '../cas/Cas'
import * as favUtils from "../../utils/favorite.utils";
import { Spin } from 'antd';
import ConsolidatedScore from "../../utils/Formula";
import CXBarChart from "../../components/attributes/bar-chart/CXBarChart";
import { Dropdown } from "react-bootstrap"

const Score = (props) => {
  const favInput = JSON.parse(JSON.stringify(props.favInput));
  //removed volatility from cxScorePage
  const { cxScore, cas,  CXScoreTrend, company_region } = props.cxScoreDetails //company_region  added for EMEA release
  const { responseCount } = props.cxScoreDetails.cas
  const consolidatedScore = ConsolidatedScore(props.cxScoreDetails, 'current')
  const consolidatedLastScore = ConsolidatedScore(props.cxScoreDetails, 'previous')
  const { sasParent } = props  
  let [FavoriteId, setFavoriteId] = useState(null)
  const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- 
  const { selectedSubRegion } = props;//added for EMEA release
  let [CXScoreKeys, setCXScoreKeys] = useState([])//cx score trend
  let [CXScorevalues, setCXScorevalues] = useState([])//cx score trend
  const [CXTrendType, setCXTrendType] = useState('Quarterly');//cx score trend
  const [CXTrendDataPoint, setCXTrendDataPoint] = useState([]);//cx score trend
  const [loading, setLoading] = useState(false)

  const [tabs, setTabs] = useState([
    {
      value: 'CX Score',
      isActive: sasParent === 'cx' ? true : false,
      url: '/dashboard/score'
    },
    {
      value: 'CAS',
      isActive: sasParent === 'cx' ? false : true,
      url: '/dashboard/score/cas'
    }
  ])
  let breadCrumb = [...props.breadCrumb];
  if (!breadCrumb.filter(element => element.value.toLowerCase() === 'cx score').length) {
    breadCrumb.push(
      {
        value: "CX Score",
        path: "/",
      }
    )
  }

  //remove the selected KPI from bread crumb
  if (props.selectedKPI) {
    breadCrumb = breadCrumb
      .filter(item => item.value.toLowerCase() !== props.selectedKPI.name.toLowerCase()
        && item.value.toLowerCase() !== utils.getChildViewName(props.selectedKPI.name).toLowerCase())
  }

  const setActive = (tab) => {
    const _tabs = [...tabs]
    _tabs.forEach(element => {
      if (element.value.toLowerCase() === tab.value.toLowerCase()) {
        element.isActive = true;
      } else {
        element.isActive = false;
      }
    })
    setTabs(_tabs)
    if (tab.value === 'CX Score')
      props.setSASParent('cx')
    else
      props.setSASParent('cas')
    props.history.push(tab.url)
  }
  //cx score trend
  // const handleSelect = (e) => {
  //   setCXTrendType(e)
  //   setCXTrendVals(e);
  // }

  const setCXTrendVals = (CXType) => {
    setCXTrendType(CXType)
    if(CXScoreTrend){
      if(CXType === "Quarterly"){
        let quaterlyArr = CXScoreTrend.quarterly;
        let keys = quaterlyArr.map(key=>key.period_value)
        let values = quaterlyArr.map(key=>(key.cxscore)?(key.cxscore).toFixed(0): "-");
        let dataPoint = quaterlyArr.map(item=>(item.datapoint)?(item.datapoint).toFixed(0): "-");
        setCXScoreKeys(keys);
        setCXScorevalues(values);
        setCXTrendDataPoint(dataPoint);
      }else{
        let monthlyArr = CXScoreTrend.monthly;
        let keys = monthlyArr.map(key=>key.period_value)
        let values = monthlyArr.map(key=>(key.cxscore)?(key.cxscore).toFixed(0): "-")
        let dataPoint = monthlyArr.map(item=>(item.datapoint)?(item.datapoint).toFixed(0): "-");
        setCXScoreKeys(keys);
        setCXScorevalues(values);
        setCXTrendDataPoint(dataPoint)
      }
    }
  }
  
  async function getFavorites() {
    const favorites = await favUtils.getFavorites()
    props.setFavorite(favorites)
  }

  async function addToFavorite(isAlreadyFavorite) {
    try {
      setLoading(true)
      if (!isAlreadyFavorite) {
        favInput.UserId = utils.getUserEmail()
        favInput.ViewType = 2
        favInput.region = region; //Added for EMEA release
        favInput.InputParameters.productDivision = props.selectedProductDivision;
        favInput.InputParameters.customer = props.selectedCustomer;
        favInput.InputParameters.sbs = props.selectedSBS;
        favInput.InputParameters.sasParent = props.sasParent;
        favInput.InputParameters.cxScoreDetails = props.cxScoreDetails;
        favInput.InputParameters.breadcrumb = breadCrumb;
        favInput.InputParameters.subregion = selectedSubRegion;//added for EMEA release
        if(props.cxScoreDetails){
          if(favInput.InputParameters.cxScoreDetails.company_region)//added for EMEA release
          favInput.InputParameters.group.company_region = favInput.InputParameters.cxScoreDetails.company_region;
        }
        const FavoriteId = await favUtils.addFavorites(favInput)
        setFavoriteId(FavoriteId)
      } else {
        await favUtils.removeFavorites(FavoriteId)
        setFavoriteId(null)
      }
      setLoading(false)
      getFavorites()
    } catch (err) {
      console.log(err);
      setLoading(false)
    }
  }

  useEffect(() => {
    setFavoriteId(favUtils.chkIsFavorite(
      props.favorites,
      region,//added for EMEA release
      2,
      props.selectedProductDivision,
      props.selectedCustomer,
      props.selectedSBS,
      '',
      '',
      selectedSubRegion
      ))
      setCXTrendVals(CXTrendType) //cx score trend
  }, [])

  return (
    <Spin spinning={loading}>
      <div className={styles.cxContainer}>
        <BreadCrumb
          list={breadCrumb}
          {...props}
          addToFavorite={addToFavorite}
          isFavorite={FavoriteId ? true : false} />
        <div className={styles.cxIndexContainer}>

          <div className={`${'row no-gutters'}`}>
            <div className={`${'col-md-6'}`}>
              <h2 className={styles.cxHeading}>Summary</h2>
            </div>
            <div className={`${'col-md-6'} ${styles.cxbartchart}`}>
              <div className={`${'col-md-7'} ${styles.noPadding}`}><h2 className={styles.cxHeading}>CX Score Trend</h2></div>
              <div className={`${'col-md-5'} ${styles.cxbartchartchild}`}>
              {/* cx score trend */}
                {/* <Dropdown onSelect={handleSelect} >
                  <Dropdown.Toggle variant="success" id="dropdown-basic" className={`${styles.btn_success_config}`}>
                    <span title={CXTrendType}>{CXTrendType}</span>
                    <span className={styles.styleArrow}>X</span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={styles.dropdownMenu1}>
                    <Dropdown.Item eventKey="Monthly">Monthly</Dropdown.Item>
                    <Dropdown.Item eventKey="Quarterly">Quarterly</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown> */}
                <div className={`${styles.cxScoreTrendBtn2} ${(CXTrendType === 'Quarterly')?styles.activeBtn:''}`} onClick={() => setCXTrendVals('Quarterly')}>Quarterly</div>
                <div className={`${styles.cxScoreTrendBtn1} ${(CXTrendType === 'Monthly')?styles.activeBtn:''}`} onClick={() => setCXTrendVals('Monthly')}>Monthly</div>
              </div>
            </div>
          </div>
          <div className={`${styles.indexContent} ${'row no-gutters'}`}>
          <div className={`${styles.chartPosition} ${'row no-gutters col-md-6'}`}>
              <div className={`${styles.chartLeft} ${'col-sm-7 col-md-6'}`}>
                {
                  cxScore.score ?
                    <GaugeChart
                      id="gauge-chart1"
                      colors={["#e71316", "#f2a041", "#4aa707"]}
                      cornerRadius={0}
                      arcWidth={0.12}
                      needleColor="#54585a"
                      textColor="#000"
                      percent={Math.round(consolidatedScore) / 100}
                      arcPadding={0.05}
                      formatTextValue={() => Math.round(consolidatedScore)}
                    /> :
                    <div className={styles.emptyGauge}></div>
                }
              </div>
              <div className={`${styles.indexChartLegend} ${'col-sm-5 col-md-6'}`}>
                <ul>
                  <li>
                    <span className={styles.percentLabel}>Consolidated Score</span>
                  </li>
                  <li>
                    {
                      cxScore.score ? <React.Fragment>
                        <span className={styles.percentValue}>{Math.round(consolidatedScore)}</span>
                        {/* <span className={(consolidatedScore === consolidatedLastScore) ?
                          styles.arrowNoChange : (consolidatedScore > consolidatedLastScore) ?
                            styles.arrowUp : styles.arrowDown}></span> */}
                      </React.Fragment> : <span className={styles.dash}>-</span>
                    }
                  </li>
                  <li>
                    <span className={styles.percentLabel}>CX Score</span>
                  </li>
                  <li>
                    {
                      cxScore.score ? <React.Fragment>
                        <span className={(cxScore.score >= cxScore.benchmark) ?
                          styles.percentValue1 : styles.percentValue2}>{Number(cxScore.score).toFixed(0)}</span>
                        <span className={(Number(cxScore.score).toFixed(0) === Number(cxScore.lastQuarterValue).toFixed(0)) ?
                          styles.arrowNoChange : (cxScore.score > cxScore.lastQuarterValue) ?
                            styles.arrowUp : styles.arrowDown}></span>
                      </React.Fragment> : <span className={styles.dash}>-</span>
                    }
                  </li>
                  <li>
                    <span className={styles.percentLabel}>CAS</span>
                  </li>
                  <li>
                    {
                      responseCount ? <React.Fragment>
                        <span className={(cas.score >= cas.benchmark) ?
                          styles.percentValue1 : styles.percentValue2}>{Number(cas.score).toFixed(0)}</span>
                        <span className={(Number(cas.score).toFixed(0) === Number(cas.lastQuarterValue).toFixed(0)) ?
                          styles.arrowNoChange : (cas.score > cas.lastQuarterValue) ?
                            styles.arrowUp : styles.arrowDown}></span>
                      </React.Fragment> : <span className={styles.dash}>-</span>
                    }
                  </li>
                  {/*<li>
                    <span className={styles.percentLabel}>Volatility</span>
                  </li><li>
                    {
                      cxScore.score ? <span className={styles.volatility}>{volatility}</span> :
                        <span className={styles.dash}>-</span>
                    }
                  </li>*/}
                </ul>
              </div>

            </div>
            <div className={`${styles.indexDesc} ${'d-block d-sm-none d-md-block col-md-6'}`}>
              <div className={styles.underDesignChild}>
                {/* <h3>Under Design</h3> */}
                <CXBarChart
                  id="cxScoreTrandingChart"
                  height={250}
                  keys={CXScoreKeys}
                  values={CXScorevalues}
                  dataPoints = {CXTrendDataPoint}
                />
              </div>
            </div>
          </div>
        </div>
        <Tabs tabs={tabs} setActive={setActive} value="Score" />
        <Switch>
          <Route
            exact
            path={`${props.match.path}`}
            render={() => (
              <CXList {...props} breadCrumb={breadCrumb} />
            )} ></Route>
          <Route
            exact
            path={`${props.match.path}/cas`}
            render={() => (
              <CAS {...props} />
            )} ></Route>
        </Switch>
      </div>
    </Spin>
  );
};

export default Score;
