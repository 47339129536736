import React, { useEffect, useState } from 'react';
import BreadCrumb from "../../components/breadcrumb/BreadCrumb";
import styles from "./detailedview.module.scss";
import KPIScore from "./Tiles/Redesign/KPIScore";
import CXScore from "./Tiles/Redesign/CXScore";
import CAS from "./Tiles/Redesign/CAS";
import { Spin } from 'antd';
import * as utils from '../../utils/utils';
import * as consts from '../../utils/constant';
import * as favUtils from '../../utils/favorite.utils'
import Attributes from '../attributes/group';
import * as constants from "../../../src/utils/constant";
import * as nbaurls from "../../../src/utils/nba.utils";
import { CreateScoreLinks } from '../score/Score.utils';
import  CXTile from "../../components/cxTile/cxTile";

const Group = (props) => {
    const favInput = JSON.parse(JSON.stringify(props.favInput));
    let [FavoriteId, setFavoriteId] = useState(null)
    const [loading, setLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- for Breadcrumbs initial loading
    const [groupDetails, setGroupDetails] = useState(null)
    const [KPI,setKPI] = useState(null)
    const [cxScr, setCxScore] = useState(null)
    const primaryRecommendation = (props.group && props.group.primaryRecommendation && props.group.primaryRecommendation.length>0) ? Array.isArray(props.group.primaryRecommendation) ? props.group.primaryRecommendation[0].toLocaleLowerCase() : props.group.primaryRecommendation.toLocaleLowerCase() : "";
    const secondaryRecommendations = (props.group && props.group.secondaryRecommendations) ? props.group.secondaryRecommendations : [];
 
    const [isattrSectionLoaded,setIsSectionLoaded] = useState(false);

    const isSectionLoaded = (input)=>{
        setIsSectionLoaded(input)
    }
    let level='group'
    //EMEA Release- - Added region for BreadCrumbs
    const breadcrumbList = [
        {
            value: `Dashboard (${region})`,
            path: "/dashboard",
        },
        {
            value: `Life Sciences (${region})`,
            path: "/",
        },
    ];
    const getGroupData = (result) => {
        let group = []
        group = (result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        if (group && group['_source'] && group['_source'].group && group['_source'].group.length > 0) {
             return group['_source'].group.filter((item)=> item.company_region.toLowerCase()===region.toLowerCase())[0]; //EMEA Release- - to find specific region data 
        }
        return group;
    }
    useEffect(() => {
        setLoading(true)
        const input = {
            name: constants.Group_ScoreDetails_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }
        setLoading(true)
        utils.getDataES('-elasticsearch', 'POST', input)  
        .then(resp => {  
                let groupESData = getGroupData(resp.data);
                console.log("get Group ES repsonse",groupESData)
                const {company_region} = groupESData
                //kpiHandler(groupESData, KpiScoreTrends, cxList); //comented kpiToPB
                setRegion(company_region)
                setGroupDetails(groupESData);
                props.setKPI({})
                props.setCXScoreData(groupESData);//added kpiToPB
                setLoading(false)
            }).catch(err => setLoading(false))
    }, [])

    const getSortedCXList = (cxList) => {
        let list = (cxList) ? [...cxList] : []
        list.forEach(element => {
            element.difference = element.score - element.lastQuarterValue
            element.differenceCent = ((element.score - element.lastQuarterValue) / (element.lastQuarterValue)) * 100
        });
        return list.sort(utils.sorting('differenceCent', 'number', false))
    }

    let cxScore, cxScoreTrend,cas, cxList, cxListDP, cxKeys, cxValues;
    if (groupDetails) {
        cxScore = groupDetails.cxScore;
        if (groupDetails && groupDetails.cxList && groupDetails.cxList.length > 0) {
            cxListDP = groupDetails.cxList.find(item => item.name.toLowerCase() === consts.HELD_ORDER_INDEX.toLowerCase());
            cxScore.dataPoints = (cxListDP && cxListDP.dataPoints) ? cxListDP.dataPoints : ''
        }
        cas = groupDetails.cas;
        cxList = getSortedCXList(groupDetails.cxList);
    }

    const createBreadCrumb = () => {
        breadcrumbList[breadcrumbList.length - 1].path = '/dashboard/groupdetail'
        return breadcrumbList;
    }

    const viewScore = (score, item) => {
        //props.setCXScoreData(groupDetails);
        props.setBreadCrumb(createBreadCrumb());
        if (score === 'cas') {
            props.setKPI();
            props.setSASParent('cas');
            props.history.push("/dashboard/score/cas");
        } else if (score === 'cx'){
            props.setKPI();
            props.setSASParent('cx');
            props.history.push("/dashboard/score");
        }
        else{
            console.log("My KPI Item is",item)
            props.setKPI(item);
            props.setSASParent('kpi');
           //props.setBannerType(item.name.toLowerCase().replace(/\s+/g, ''));
            props.history.push("/dashboard/primaryview");
        }
    };

    const getInsights = (nba) => {
        if (groupDetails.nba_insights) {
            let insights = groupDetails.nba_insights.filter(element => element.metrics_name.toLowerCase() === nba.toLowerCase())
            if (insights && insights.length) {
                return insights[0].insights
            }
            return []
        }
        return []
    }

    const primaryView = (nba) => {
        let _nba = ''
        const urls = nbaurls.group_nba_urls(nba, region);
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.IMPROVE_INVOICE_AGING)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_INVOICE_AGING.replace(/\s+/g, ''));
                break;
            case consts.REDUCE_RETURN_PERCENTAGE:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.REDUCE_RETURN_PERCENTAGE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.REDUCE_RETURN_PERCENTAGE.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.IMPROVE_PRODUCT_AVAILABILITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_PRODUCT_AVAILABILITY.replace(/\s+/g, ''));
                break;
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.NEW_IMPROVE_ORDER_VELOCITY)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.NEW_IMPROVE_ORDER_VELOCITY.replace(/\s+/g, ''));
                break;
            case consts.IMPROVE_OTIF:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.IMPROVE_OTIF)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.IMPROVE_OTIF.replace(/\s+/g, ''));
                break;
            case consts.INCREASE_CREDITLINE:
                _nba = {
                    name: nba,
                    primaryView: urls.primaryView,
                    childView: urls.childView,
                    historyView: urls.historyView,
                    insights: getInsights(consts.INCREASE_CREDITLINE)
                }
                props.setNBA(_nba);
                props.setBannerType(consts.INCREASE_CREDITLINE.replace(/\s+/g, ''));
                break;
            default:
                break;
        }

        props.setNBABreadCrumb(createBreadCrumb())
        props.history.push('/dashboard/nba/primaryview')
    }

    useEffect(() => {
        props.setCustomer({})
        props.setSelectedSBS({})
        props.setProductDivision({})
        setFavoriteId(favUtils.chkIsFavorite(props.favorites,region, 1)) //region added for EMEA release
    }, [])
    //added kpiToPB
    useEffect(()=>{
     if(props.cxScoreDetails && groupDetails){
            console.log("Group.js UseEffect--props",props);
            const navList = CreateScoreLinks(props, false); console.log("CreateScoreLinks In UseEffect--Group.js",navList, groupDetails)
            const {KpiScoreTrends, cxList} = groupDetails;
            kpiHandler(groupDetails, KpiScoreTrends, cxList, navList); 
     }
    },[props.cxScoreDetails,groupDetails])

    const kpiHandler = (group, KpiScoreTrends, cxList, navList) => {console.log("My KPI--kpiHandler--All Inputs--group", group,"KpiScoreTrends",KpiScoreTrends,"cxList",cxList,"navList",navList);
    let kpiSortedList, newKpiList;
             kpiSortedList = getSortedCXList(cxList);  console.log("My KPI Sorted List",kpiSortedList); 

             newKpiList = kpiSortedList.map( (item) => {
                let newKPIMergedObj, kpiName, navItm;
               
                kpiName = item.name.split(" ").join("").toLowerCase(); console.log("My KPI kpiSortedList--List name",kpiName)
                navItm= navList.find((item => item.name.split(" ").join("").toLowerCase()=== kpiName));
                let {primary, child, history} = navItm;

                for (const [key, value] of Object.entries(KpiScoreTrends)) { console.log(`My KPI ${key}: ${value}`);
                    let kpiName_scoreTrend = key.split(" ").join("").toLowerCase();
                    if(kpiName===kpiName_scoreTrend){//compare cxList KPI name and kpiScoretrend kpi name 
                       let keys, values;
                        keys = value.quarterly.map(key=>key.period_value);
                        values = handleKPIChartValues(value,kpiName) ;  console.log("MY KPI Score trend Keys-----",keys);  console.log("My KPI Score trend Values-----",values);
                        
                        newKPIMergedObj={...item, ...value, kpiKeys:keys, kpiValues:values, primary, child, history } ; console.log("My KPI newKPIMergedObj",newKPIMergedObj)
                    }   
                }
                return newKPIMergedObj
            }); console.log("My KPI MergedList with Primary/Hist/Child",newKpiList);
            setKPI(newKpiList)

            cxScoreTrend = group.CXScoreTrend; console.log("group, cxScoreTrend", cxScoreTrend);

            for (const [key, value] of Object.entries(cxScoreTrend)) {
                if(key==="quarterly"){
                    cxKeys = cxScoreTrend[key].map(ky=>ky.period_value); console.log("cxKeys",cxKeys, value);
                    cxValues = handleKPIChartValues(value,"cxScoreTrend"); console.log("cxValues",cxValues);
                }
            }
    
            let cxObj = {...cxScore,cxKeys:cxKeys,cxValues:cxValues};
            setCxScore(cxObj)
    }
 
    const handleKPIChartValues = (values, kpi) =>{  console.log("My KPI handleKPIChartValues",values,kpi); 
        let myChartValueArray, tempScoreVar;
        if(kpi==='cxScoreTrend'){
            tempScoreVar = 'cxscore';
            myChartValueArray = values.map((item)=> item[tempScoreVar]? item[tempScoreVar].toFixed(0): "-");
            console.log("My KPI myChartValueArray--1",kpi," ",myChartValueArray);    
        }
        else{
            tempScoreVar='score';
            myChartValueArray = values.quarterly.map((item)=> item[tempScoreVar]? item[tempScoreVar].toFixed(0): "-");
            console.log("My KPI myChartValueArray--2",kpi," ",myChartValueArray);    
        }
        
        return myChartValueArray;

    }

    async function getFavorites() {
        const favorites = await favUtils.getFavorites()
        props.setFavorite(favorites)
    }

    async function addToFavorite(isAlreadyFavorite) {
        try {
            setLoading(true)
            if (!isAlreadyFavorite) {
                favInput.UserId = utils.getUserEmail()
                favInput.ViewType = 1
                favInput.region = region;
                favInput.InputParameters.group = props.group;
                const FavoriteId = await favUtils.addFavorites(favInput)
                setFavoriteId(FavoriteId)
            } else {
                await favUtils.removeFavorites(FavoriteId)
                setFavoriteId(null)
            }
            setLoading(false)
            getFavorites()
        } catch (err) {
            console.log(err);
            setLoading(false)
        }
    }

    const nbas = [
        {
            nba: consts.NEW_IMPROVE_ORDER_VELOCITY,
            primaryRecommendation: primaryRecommendation === consts.NEW_IMPROVE_ORDER_VELOCITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.NEW_IMPROVE_ORDER_VELOCITY)
        },
        {
            nba: consts.INCREASE_CREDITLINE,
            primaryRecommendation: primaryRecommendation === consts.INCREASE_CREDITLINE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.INCREASE_CREDITLINE)
        },
        {
            nba: consts.IMPROVE_INVOICE_AGING,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_INVOICE_AGING.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_INVOICE_AGING)
        },
        {
            nba: consts.IMPROVE_PRODUCT_AVAILABILITY,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_PRODUCT_AVAILABILITY.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_PRODUCT_AVAILABILITY)
        },
        {
            nba: consts.IMPROVE_OTIF,
            primaryRecommendation: primaryRecommendation === consts.IMPROVE_OTIF.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.IMPROVE_OTIF)
        },
        {
            nba: consts.REDUCE_RETURN_PERCENTAGE,
            primaryRecommendation: primaryRecommendation === consts.REDUCE_RETURN_PERCENTAGE.toLowerCase() ? true : false,
            isOutlineRequired: utils.getRecommendationFlag(secondaryRecommendations,consts.REDUCE_RETURN_PERCENTAGE)
        }
        
    ]

    return (
        <Spin spinning={loading}>
            <div className={styles.cxContainer}>
                <React.Fragment>
                    <BreadCrumb
                        list={breadcrumbList}
                        {...props}
                        addToFavorite={addToFavorite}
                        isFavorite={FavoriteId ? true : false} />
                    <div className="row no-gutters">
                       {<div className={`${groupDetails && isattrSectionLoaded? styles.bgColor: styles.nobgColor } ${styles.rightContent} ${"col-sm-12 col-md-12 col-lg-4"}`}>
                            <Attributes isattrSectionLoaded={isSectionLoaded}/>
                        </div>}
                        <div className={`${styles.leftContent} ${"col-sm-12 col-md-12 col-lg-8"}`}>
                            {
                                groupDetails ?
                                    <React.Fragment>
                                        <div className={`${styles.layoutBx} ${"col-sm-12 col-md-12 col-lg-12"}`}>
                                            <h2 className={styles.cxHeading}>Next Best Actions</h2>
                                            <div className={styles.nbaContainer}>
                                                {
                                                    nbas.map((element, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => primaryView(element.nba)}
                                                            className={element.primaryRecommendation ? `${styles.griditem} ${styles.recommended}` :
                                                                element.isOutlineRequired ? `${styles.griditem} ${styles.otherRcmds}` : `${styles.griditem} ${styles.noRcmds} `}
                                                        >{element.nba}</div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    
                                    <div className={`${styles.redesignContainer} ${"col-sm-12 col-md-12 col-lg-12"}`}>
                                        
                                        {   <div className={`${styles.scoreGridContainer} ${"col-sm-12 col-md-12 col-lg-10"}`}>
                                              <div className={`${styles.scoreGridHeader} ${"col-sm-12 col-md-12 col-lg-12"}`}>
                                                   <span>CX Score</span>
                                              </div>
                                              <div className={`${styles.scoreGrid} ${"col-sm-12 col-md-12 col-lg-12"}`}>
                                             {<div className={`${styles.cxScoreContainer}`}>
                                                    {cxScore && cxScr && <CXScore cxData={cxScr} cxScore={cxScore} {...props} viewScore={viewScore} />}
                                            </div>}
                                            
                                            <div className={`${styles.kpiContainer}`}>
                                                <div className={`${styles.flexContainer}`}>
                                                {KPI && <KPIScore kpiIndex={3} kpi={KPI}   {...props} viewScore={viewScore} />}
                                                {KPI && <KPIScore kpiIndex={4} kpi={KPI}   {...props} viewScore={viewScore} />}
                                                {KPI && <KPIScore kpiIndex={1} kpi={KPI}   {...props} viewScore={viewScore} />}
                                                </div>
                                                 <div className={`${styles.flexContainer}`}>
                                                 {KPI && <KPIScore kpiIndex={2} kpi={KPI}  {...props} viewScore={viewScore} />}
                                            {KPI && <KPIScore kpiIndex={5} kpi={KPI}    {...props} viewScore={viewScore} />}
                                            {KPI && <KPIScore  kpiIndex={6} kpi={KPI}  {...props} viewScore={viewScore} />}
                                                </div>
                                            </div>
                                            
                                              </div>
                                           </div>
                                        }
                                        {
                                            <div className={`${styles.casContainer}   ${"col-sm-12 col-md-12 col-lg-2"}`}>
                                                    {cas && <CAS  cas={cas} {...props} viewScore={viewScore} type={level} />}
                                            </div>
                                        }
                                        
                                    </div>
                                    
                                      
                                    
                                    </React.Fragment> : null
                            }
                        </div>
                        
                    </div>
                </React.Fragment>
            </div>
        </Spin>
    );
};

export default Group;
