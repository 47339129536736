import React from "react";
import styles from "../tiles.module.scss";
import Chevron from "../../../../components/chevron/Chevron";
import * as utils from '../../../../utils/utils';
import CXBarChartSm from '../../../../components/chart-sm/Chart-sm';

const CustCAS = (props) => {
    const { cas, type} = props;
    const {score, lastQuarterValue, responseCount} = cas

    /*const getSign = (score,lastQuarterValue)=>{
    if(Number(responseCount).toFixed(0)!== 0){
        if(Number(score).toFixed(0) > Number(lastQuarterValue).toFixed(0) )
         return '+'
         if(Number(score).toFixed(0) < Number(lastQuarterValue).toFixed(0) )
         return '-'
         if(Number(score).toFixed(0) === Number(lastQuarterValue).toFixed(0) )
         return '' 

    }

   }*/

   /*const getDifference = (score,lastQuarterValue)=>{
         if(Number(score).toFixed(0) === Number(lastQuarterValue).toFixed(0) )
         return true;
         else
         return false; 
   }*/

    return (
        <div
            className={styles.casCustCardContainer}
            onClick={() => props.viewScore('cas')}>
            
            <div className={styles.casCardHeader}></div>
            <div className={styles.casCustCardBody}>
                <div className={styles.custCardTitle}>CAS Score</div>
                <div className={styles.progressValCustBody}>
                    {
                        !responseCount ? `-` : <span className={`${styles.progressVal} ${(score!==lastQuarterValue) ? score > lastQuarterValue ? 'text-color-green' : 'text-color-red': 'text-color-yellow' }`}>{Number(score).toFixed(0)}
                        {/*<span className={`${styles.chevron} `}><Chevron className={`accordion__icon ${score < lastQuarterValue ? styles.rotate : null}`} width={15} fill={"#000"} /></span>*/}
                        </span>
                    }
                </div>
                {type!=='subregion' && <div className={styles.casCustFooter}>
                    { <>
                        <span>{`CAS Surveys = ${responseCount}`}</span>
                         <span className={styles.desc}>{`Includes Customer Service, Order Fulfillment and Sales surveys`}</span>
                    </>
                         
                    } </div>}
            </div>
        </div>
    )
}
export default CustCAS;