import React, { useEffect, useState } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import { useHttpGet } from "../../components/hooks/useHttp";
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";

function SBSAttribute(props) {

    const [customers, setCustomers] = useState(null)
    const [regions, setRegions] = useState(null)
    //const [custGained, setCustGained] = useState(null) //Remove Customers Gained & Lost section 
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [sbsDetails, setsbsDetails] = useState(null)
    const [omc, setOMC] = useState(null)//Add Revenue Distribution by order mode channel
    const [isLoading, setIsLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- to fetch region specific data

    const processData = (fetchedData) => {//EMEA Release- - Business Unit level adding region filter
        const divisionDetails = fetchedData.productDivision.filter(item => (item.code.toLowerCase() === props.divisionCode.toLowerCase() && item.company_region.toLowerCase()=== region.toLowerCase()))[0];
        const sbsDetails = divisionDetails.businessUnits.filter(item => (item.code.toLowerCase() === props.code.toLowerCase() && item.company_region.toLowerCase()=== region.toLowerCase()))[0];
        //Remove Customers Gained & Lost section 
        /*const obj = {
            gained: sbsDetails.customerGained,
            lost: sbsDetails.customerLost
        }
        setCustGained({ ...obj })*/

        const open_backorder = {
            open: sbsDetails.openorder,
            back: sbsDetails.backorder
        }
        setOpen_Backorder(open_backorder)

        if (sbsDetails.revenueDistribution && sbsDetails.revenueDistribution.customers) {
            const customers = [...sbsDetails.revenueDistribution.customers]
            setCustomers(customers.sort(utils.sorting('revenue', "number", false)))
        }
        //Add Revenue Distribution by order Mode Entry method
        if (sbsDetails && sbsDetails.revenueDistribution && sbsDetails.revenueDistribution.omc &&
            sbsDetails.revenueDistribution.omc.length > 0) {
                console.log("BU OMC  Keys---------",Object.keys(sbsDetails.revenueDistribution.omc))
                const ofms = [...sbsDetails.revenueDistribution.omc]
                setOMC([...ofms.sort(utils.sorting('revenue', "number", false))])
        }

        return sbsDetails
    };
    const getGroupData = (result) => {
        const group = (result && result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        return (group && group['_source']) ? group['_source'] : {};
    }
    useEffect(() => {
        setIsLoading(true)
        const input = {
            name: constants.SBS_Attributes_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }
         utils.getDataES('-elasticsearch', 'POST',input)   
         .then(resp => {
                let groupESData = getGroupData(resp.data);
                let result = processData(groupESData)
                const {company_region} = result
                setRegion(company_region)
                setsbsDetails(result)
                props.isattrSectionLoaded(true);
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }, [])
    return (
        sbsDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>{`${props.code} - ${region} (Division Level View)`}</div>
            <div className={styles.attributesBody}>
                {/* <div className={`${styles.groupTopSection}`}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.totalRevenue)?'$'+utils.convertToInternationalCurrencySystem(sbsDetails.totalRevenue.toFixed(2)):'-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>YTD Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.YTDGrowth)?sbsDetails.YTDGrowth.toFixed(2)+'%':'-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.QoQGrowth)?sbsDetails.QoQGrowth.toFixed(2)+'%':'-'}</div>
                    </div>
                </div> */}
                {//Remove Total YTD Revenue, Total YoY Growth, and Total QoQ Growth boxes
                /*<div className={`${styles.groupTopSection} ${styles.borderBottom}`}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.globaltotalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(sbsDetails.globaltotalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YoY Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.globalYTDGrowth !== undefined) ? sbsDetails.globalYTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.globalQoQGrowth !== undefined) ? sbsDetails.globalQoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>*/}
                <div>
                    {
                         <Accordion
                         title="Top 5 Customers by Revenue​ ($M)"
                         type="customerRevenue"
                         customers={customers}
                         voiceRevenue={sbsDetails.totalRevenue}
                     />
                    }
                </div>
                <div className={styles.groupTopSection}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.totalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(sbsDetails.totalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE YoY Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.YTDGrowth !== undefined) ? sbsDetails.YTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(sbsDetails.QoQGrowth !== undefined) ? sbsDetails.QoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>
                <div>
                   {
                        <Accordion
                            title="Revenue Distribution by Order Entry Channel (YTD)"
                            type="OMC"
                            list={omc}
                        />
                    }
                    <Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                    />
                   
                    {//Remove Customers Gained & Lost section 
                    /*<Accordion title="Customers Gained/Lost"
                        type="customerGained"
                        custGained={custGained}
                    />*/}
                </div>
            </div>
        </div>)
}

export default SBSAttribute