import * as consts from '../utils/constant';
export const group_nba_urls = (nba, reg) => {
    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
    if(region.toLowerCase()==="na"){
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_Region/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/company_region eq 'NA'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_Region_Complaints/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Complaints/company_region eq 'NA'` ,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/company_region eq 'NA'`,  
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/company_region eq 'NA'`,   
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/company_region eq 'NA'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '-' and Company_Region/company_region eq 'NA' and Rank3/product_division_code eq '-' and Rank3/business_segment_code eq '-' and Rank3/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'NA'`,
                }
            default:
                return ''
        }
    } 
        else 
    {
            switch (nba) {
                case consts.IMPROVE_INVOICE_AGING:
                    return {
                        primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                        childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_EMEA_CHILD_LINK}&filter=Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Company_Sub_Region/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/company_region eq 'EMEA'`,
                    }
                case consts.REDUCE_RETURN_PERCENTAGE:
                    return {
                        primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                        childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Company_Sub_Region_Comp/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                        historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Complaints/company_region eq 'EMEA'`,
                    }
                case consts.IMPROVE_PRODUCT_AVAILABILITY:
                    return {
                        primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                        childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
                    }
                case consts.NEW_IMPROVE_ORDER_VELOCITY:
                    return {
                        primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                        childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
                    }
                case consts.IMPROVE_OTIF:
                    return {
                        primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                        childView: `${process.env.REACT_APP_IMPROVE_OTIF_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                        historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
                    }
                case consts.INCREASE_CREDITLINE:
                    return {
                        primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                        childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/company_sub_region eq '-' and Rank/product_division_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '-' and Company_Region/company_region eq 'EMEA' and Rank3/product_division_code eq '-' and Rank3/business_segment_code eq '-' and Rank3/company_sub_region eq '-'`,
                        historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
                    }
                default:
                    return ''
            }
    }
  
}
export const customer_nba_urls = (nba, code, reg) => {
    code = (code)?encodeURIComponent(code):code;
    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
    if(region.toLowerCase()==="na"){
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Invoice2/account_name eq '${code}' and Company_Region/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/account_name eq '${code}' and Invoice/company_region eq 'NA'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Region_Complaints/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Complaints/company_region eq 'NA'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK_CUSTOMER}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CUST_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Salesmetrics2/company_sub_region eq '-' and Rank3/product_division_code eq '-' and Rank3/business_segment_code eq '-' and Rank3/company_sub_region eq '-' and Invoice3/account_name eq '${code}' and Company_Region/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.RECURRING_ORDER:
                    return {
                    primaryView: `${process.env.REACT_APP_RECOMMEND_RECURRING_ORDER_PRIMARY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_RECOMMEND_RECURRING_ORDER_CHILD_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_RECOMMEND_RECURRING_ORDER_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`
                    }
            default:
                return ''
        }
    } 
    else
    {
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_EMEA_CHILD_LINK}&filter=Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Invoice2/account_name eq '${code}' and Company_Sub_Region/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/account_name eq '${code}' and Invoice/company_region eq 'EMEA'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Sub_Region_Comp/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Complaints/company_region eq 'EMEA'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '-' and Rank2/business_segment_code eq '-' and Sales2/account_name eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK_CUSTOMER}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CUST_CHILD_LINK}&filter=Rank/product_division_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Salesmetrics2/company_sub_region eq '-' and Rank3/product_division_code eq '-' and Rank/company_sub_region eq '-' and Rank3/company_sub_region eq '-' and Invoice3/account_name eq '${code}' and Company_Region/company_region eq 'EMEA'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.RECURRING_ORDER:
                    return {
                    primaryView: `${process.env.REACT_APP_RECOMMEND_RECURRING_ORDER_PRIMARY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                    childView: `${process.env.REACT_APP_RECOMMEND_RECURRING_ORDER_CHILD_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                    historyView: `${process.env.REACT_APP_RECOMMEND_RECURRING_ORDER_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`
                    }
            default:
                return ''
        }
    } 
}
export const pd_customer_nba_urls = (nba, code, divisionCode) => {
    code = (code)?encodeURIComponent(code):code;
    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
    if(region.toLowerCase()==="na"){
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Invoice/product_division_code eq '${divisionCode}' and Invoice2/product_division_code eq '${divisionCode}' and Invoice2/account_name eq '${code}' and Company_Region/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${divisionCode}' and Invoice/account_name eq '${code}' and Invoice/company_region eq 'NA'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Region_Complaints/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Complaints/company_region eq 'NA'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK_CUSTOMER}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CUST_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Invoice3/product_division_code eq '${divisionCode}' and Invoice3/account_name eq '${code}' and Rank3/product_division_code eq '${divisionCode}' and Rank3/business_segment_code eq '-' and Company_Region/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'NA'`,
                }
            default:
                return ''
        }
    }
    else
    {
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_EMEA_CHILD_LINK}&filter=Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '-' and Invoice/product_division_code eq '${divisionCode}' and Invoice2/product_division_code eq '${divisionCode}' and Invoice2/account_name eq '${code}' and Company_Sub_Region/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${divisionCode}' and Invoice/account_name eq '${code}' and Invoice/company_region eq 'EMEA'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Sub_Region_Comp/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Complaints/company_region eq 'EMEA'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${divisionCode}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/account_name eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK_CUSTOMER}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PD_CUST_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '-' and Rank3/product_division_code eq '${divisionCode}' and Rank3/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '${code}' and Invoice3/product_division_code eq '${divisionCode}' and Invoice3/account_name eq '${code}' and Company_Region/company_region eq 'EMEA'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/account_name eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            default:
                return ''
        }
    }
}
export const pd_nba_urls = (nba, code, reg) => {
    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
    if(region.toLowerCase()==="na"){
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank2/product_division_code eq '${code}' and Rank2/business_segment_code eq '-' and Invoice/product_division_code eq '${code}' and Invoice2/product_division_code eq '${code}' and Company_Region/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${code}' and Invoice/company_region eq '${region}'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Complaints/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Complaints/company_region eq '${region}'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Sales/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Sales/company_region eq '${region}'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Sales/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Sales/company_region eq '${region}'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Sales/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Sales/company_region eq '${region}'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Company_region_sm/company_region eq '${region}'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '-' and Rank3/product_division_code eq '${code}' and Rank3/business_segment_code eq '-' and Rank3/company_sub_region eq '-' and Company_Region/company_region eq 'NA'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'NA'`,
                }
            default:
                return ''
        }
    }
    else{
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_EMEA_CHILD_LINK}&filter=Rank2/product_division_code eq '${code}' and Rank2/business_segment_code eq '-' and Invoice/product_division_code eq '${code}' and Invoice2/product_division_code eq '${code}' and Company_Region/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${code}' and Invoice/company_region eq '${region}'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${code}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Complaints/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Complaints/company_region eq '${region}'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${code}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Sales/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Sales/company_region eq '${region}'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${code}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Sales/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Sales/company_region eq '${region}'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${code}' and Rank2/business_segment_code eq '-' and Sales/product_division_code eq '${code}' and Sales2/product_division_code eq '${code}' and Company_Region_Sales/company_region eq '${region}'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${code}' and Sales/company_region eq '${region}'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Company_region_sm/company_region eq '${region}' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_EMEA_CHILD_LINK}&filter=Rank/company_sub_region eq '-' and Rank/product_division_code eq '${code}' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '${code}' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '-' and Rank3/product_division_code eq '${code}' and Rank3/business_segment_code eq '-' and Rank3/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
                }
            default:
                return ''
        }
    }
}
export const bu_nba_urls = (nba, code,divisionCode) => {
    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
    if(region.toLowerCase()==="na"){
    switch (nba) {
        case consts.IMPROVE_INVOICE_AGING:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Invoice/product_division_code eq '${divisionCode}' and Invoice/business_segment_code eq '${code}' and Invoice2/product_division_code eq '${divisionCode}' and Invoice2/business_segment_code eq '${code}' and Company_Region/company_region eq 'NA'`,
                historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${divisionCode}' and Invoice/business_segment_code eq '${code}' and Invoice/company_region eq 'NA'`,
            }
        case consts.REDUCE_RETURN_PERCENTAGE:
            return {
                primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Region_Complaints/company_region eq 'NA'`,
                historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Complaints/company_region eq 'NA'`,
            }
        case consts.IMPROVE_PRODUCT_AVAILABILITY:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales/company_region eq 'NA'`,
            }
        case consts.NEW_IMPROVE_ORDER_VELOCITY:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales/company_region eq 'NA'`,
            }
        case consts.IMPROVE_OTIF:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'NA'`,
                childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Region_Sales/company_region eq 'NA'`,
                historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales/company_region eq 'NA'`,
            }
        case consts.INCREASE_CREDITLINE:
            return {
                primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Company_region_sm/company_region eq 'NA'`,
                childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '-' and Rank3/product_division_code eq '${divisionCode}' and Rank3/business_segment_code eq '${code}' and Rank3/company_sub_region eq '-' and Company_Region/company_region eq 'NA'`,
                historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'NA'`,
            }
        default:
            return ''
    }
    }
    else{
        switch (nba) {
            case consts.IMPROVE_INVOICE_AGING:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_EMEA_CHILD_LINK}&filter=Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '${code}' and Invoice/product_division_code eq '${divisionCode}' and Invoice/business_segment_code eq '${code}' and Invoice2/product_division_code eq '${divisionCode}' and Invoice2/business_segment_code eq '${code}' and Company_Sub_Region/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/product_division_code eq '${divisionCode}' and Invoice/business_segment_code eq '${code}' and Invoice/company_region eq 'EMEA'`,
                }
            case consts.REDUCE_RETURN_PERCENTAGE:
                return {
                    primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Sub_Region_Comp/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Complaints/company_region eq 'EMEA'`,
                }
            case consts.IMPROVE_PRODUCT_AVAILABILITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.NEW_IMPROVE_ORDER_VELOCITY:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.IMPROVE_OTIF:
                return {
                    primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Salesmetrics2/product_division_code eq '${divisionCode}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_IMPROVE_OTIF_EMEA_CHILD_LINK}&filter=Rank2/primary_group eq 'Life Sciences' and Rank2/product_division_code eq '${divisionCode}' and Rank2/business_segment_code eq '${code}' and Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales2/product_division_code eq '${divisionCode}' and Sales2/business_segment_code eq '${code}' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank2/company_sub_region eq '-'`,
                    historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/product_division_code eq '${divisionCode}' and Sales/business_segment_code eq '${code}' and Sales/company_region eq 'EMEA'`,
                }
            case consts.INCREASE_CREDITLINE:
                return {
                    primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '${divisionCode}' and Rank/business_segment_code eq '${code}' and Company_region_sm/company_region eq 'EMEA' and Rank/company_sub_region eq '-' and Salesmetrics2/company_sub_region eq '-'`,
                    childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Rank/company_sub_region eq '-' and Rank/business_segment_code eq '${code}' and Salesmetrics2/business_segment_code eq '${code}' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '-' and Rank3/business_segment_code eq '${code}' and Rank3/company_sub_region eq '-' and Company_Region/company_region eq 'EMEA'`,
                    historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
                }
            default:
                return ''
        } 
    }
}
export const sr_nba_urls = (nba, code,reg) => {
    let region = JSON.parse(sessionStorage.getItem("selectedRegion"));
    switch (nba) {
        case consts.IMPROVE_INVOICE_AGING:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${code}'`,
                childView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_CHILD_LINK}&filter=Company_Sub_Region/company_region eq 'EMEA' and Rank/company_sub_region eq '${code}'`,//Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Invoice/product_division_code eq '-' and Invoice2/product_division_code eq '-' and 
                historyView: `${process.env.REACT_APP_IMPROVE_INVOICE_AGING_HISTORY_LINK}&filter=Invoice/company_region eq '${region}' and Invoice/company_sub_region eq '${code}'`,
            }
        case consts.REDUCE_RETURN_PERCENTAGE:
            return {
                primaryView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${code}'`,
                childView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Company_Sub_Region_Comp/company_region eq 'EMEA' and Rank/company_sub_region eq '${code}' and Company_Sub_Region_Comp/company_sub_region eq '${code}'`,//and Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '-' and Sales2/product_division_code eq '-'
                historyView: `${process.env.REACT_APP_REDUCE_RETURN_PERCENTAGE_HISTORY_LINK}&filter=Complaints/company_region eq '${region}' and Complaints/company_sub_region eq '${code}' `,
            }
        case consts.IMPROVE_PRODUCT_AVAILABILITY:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${code}'`,
                childView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank/company_sub_region eq '${code}' and Company_Sub_Region_Sales/company_sub_region eq '${code}'`,//Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '-' and Sales2/product_division_code eq '-' and 
                historyView: `${process.env.REACT_APP_IMPROVE_PRODUCT_AVAILABILITY_HISTORY_LINK}&filter=Sales/company_region eq '${region}' and Sales/company_sub_region eq '${code}'`,
            }
        case consts.NEW_IMPROVE_ORDER_VELOCITY:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${code}'`,
                childView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank/company_sub_region eq '${code}' and Company_Sub_Region_Sales/company_sub_region eq '${code}'`,// Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '-' and Sales2/product_division_code eq '-' and 
                historyView: `${process.env.REACT_APP_IMPROVE_ORDER_VELOCITY_HISTORY_LINK}&filter=Sales/company_region eq '${region}' and Sales/company_sub_region eq '${code}'`,
            }
        case consts.IMPROVE_OTIF:
            return {
                primaryView: `${process.env.REACT_APP_IMPROVE_OTIF_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${code}'`,
                childView: `${process.env.REACT_APP_IMPROVE_OTIF_CHILD_LINK}&filter=Rank/primary_group eq 'Life Sciences' and Company_Sub_Region_Sales/company_region eq 'EMEA' and Rank/company_sub_region eq '${code}' and Company_Sub_Region_Sales/company_sub_region eq '${code}'`,//Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Sales/product_division_code eq '-' and Sales2/product_division_code eq '-' and 
                historyView: `${process.env.REACT_APP_IMPROVE_OTIF_HISTORY_LINK}&filter=Sales/company_region eq '${region}' and Sales/company_sub_region eq '${code}'`,
            }
        case consts.INCREASE_CREDITLINE:
            return {
                primaryView: `${process.env.REACT_APP_INCREASE_CREDITLINE_PRIMARY_LINK}&filter=Rank/product_division_code eq '-' and Rank/business_segment_code eq '-' and Company_region_sm/company_region eq '${region}' and Company_sub_region/company_sub_region eq '${code}'`,
                childView: `${process.env.REACT_APP_INCREASE_CREDITLINE_CHILD_LINK}&filter=Company_Region/company_region eq 'EMEA' and Rank/company_sub_region eq '${code}' and Rank/product_division_code eq '-' and Salesmetrics2/product_division_code eq '-' and Salesmetrics2/business_segment_code eq '-' and Salesmetrics2/account_name eq '-' and Salesmetrics2/company_sub_region eq '${code}' and Rank3/product_division_code eq '-' and Rank3/business_segment_code eq '-' and Rank3/company_sub_region eq '${code}'`,
                historyView: `${process.env.REACT_APP_INCREASE_CREDITLINE_HISTORY_LINK}&filter=Sales/company_region eq 'EMEA'`,
            }
        default:
            return ''
    }
}