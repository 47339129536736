import React, { useEffect, useState } from 'react';
import Accordion from "../../components/accordion/Accordion";
import styles from './attributes.module.scss';
import { useHttpGet } from "../../components/hooks/useHttp";
import * as utils from '../../utils/utils';
import * as constants from "../../../src/utils/constant";

function SubRegionAttribute(props) {
    const { code } = props;
    const [customers, setCustomers] = useState(null)
    const [customerSegment, setCustomerSegment] = useState(null)
    const [productDivision, setProductDivision] = useState(null)
    const [regions, setRegions] = useState(null)
    //const [custGained, setCustGained] = useState(null) //Remove Cust Gained lost section
    const [open_backorder, setOpen_Backorder] = useState(null)
    const [subRegionDetails, setSubRegionDetails] = useState(null)
    const [omc, setOMC] = useState(null)//Add Revenue Distribution by order mode channel
    const [skus, setskus] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [region, setRegion] = useState(JSON.parse(sessionStorage.getItem("selectedRegion")));//EMEA Release- to fetch region specific data

    const processData = (fetchedData) => {

        //const subRegionDetails = { ...fetchedData.group } //commented for EMEA release
        const subRegionDetails = { ...fetchedData }

        if (subRegionDetails.revenueDistribution && subRegionDetails.revenueDistribution.customerSegment) {
            const customerSegment = [...subRegionDetails.revenueDistribution.customerSegment]
            setCustomerSegment([...customerSegment.sort(utils.sorting('revenue', "number", false))])
        }
        if (subRegionDetails.revenueDistribution && subRegionDetails.revenueDistribution.productDivisions) {
            const productDivision = [...subRegionDetails.revenueDistribution.productDivisions]
            setProductDivision([...productDivision.sort(utils.sorting('revenue', "number", false))])
        }
          //Add Revenue Distribution by order Mode Entry method
        if (subRegionDetails && subRegionDetails.revenueDistribution && subRegionDetails.revenueDistribution.omc &&
            subRegionDetails.revenueDistribution.omc.length > 0) {
            console.log("Subregion OMC  Keys---------",Object.keys(subRegionDetails.revenueDistribution.omc))
            const ofms = [...subRegionDetails.revenueDistribution.omc]
            setOMC([...ofms.sort(utils.sorting('revenue', "number", false))])
        }
        //Remove Cust gained/lost section
        /*const obj = {
            gained: subRegionDetails.customerGained,
            lost: subRegionDetails.customerLost
        }
        setCustGained({ ...obj })*/

        const open_backorder = {
            open: subRegionDetails.openorder,
            back: subRegionDetails.backorder
        }
        setOpen_Backorder(open_backorder)
        if (subRegionDetails.revenueDistribution && subRegionDetails.revenueDistribution.skus) {
            const skus = [...subRegionDetails.revenueDistribution.skus]
            setskus([...skus.sort(utils.sorting('revenue', "number", false))])
        }

        if (subRegionDetails.revenueDistribution && subRegionDetails.revenueDistribution.customers) {
            const customers = [...subRegionDetails.revenueDistribution.customers]
            setCustomers(customers.sort(utils.sorting('revenue', "number", false)))
        }

        return subRegionDetails
    };

    const getGroupData = (result) => {
        const group = (result && result.hits && result.hits.hits && result.hits.hits.length > 0) ? result.hits.hits[0] : [];
        let SubRegionAttrData = (group && group['_source']) ? group['_source'] : [];
        let filteredSubRegionAttrData = SubRegionAttrData.group.filter((item) => item.code.toLowerCase() === code.toLowerCase())[0];
        return filteredSubRegionAttrData;
    };

    useEffect(() => {
        setIsLoading(true)
        const input = {
            name: constants.SubRegion_Attributes_Index,
            queryParams: {
                "query": {
                    "match_all": {}
                }
            }
        }
        utils.getDataES('-elasticsearch', 'POST', input)
            .then(resp => {
                let groupESData = getGroupData(resp.data);
                let result = processData(groupESData)
                setSubRegionDetails(result)
                props.isattrSectionLoaded(true);
                setIsLoading(false)
            }).catch(err => setIsLoading(false))
    }, [])

    return (
        subRegionDetails && <div className={styles.attributes}>
            <div className={styles.attributesHeader}>{`${props.code} - ${region} (Sub Region Level View)`}</div>
            <div className={styles.attributesBody}>
                {//Remove Voice YTD Revenue 
                /*<div className={`${styles.groupTopSection} ${styles.borderBottom}`}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(subRegionDetails.globaltotalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(subRegionDetails.globaltotalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total YoY Growth​</div>
                        <div className={styles.bodyValue}>{(subRegionDetails.globalYTDGrowth !== undefined) ? subRegionDetails.globalYTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>Total QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(subRegionDetails.globalQoQGrowth !== undefined) ? subRegionDetails.globalQoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>*/}
                <div>
                    {
                        <Accordion
                            title="Top 5 Customers by Revenue​ ($M)"
                            type="customerRevenue"
                            customers={customers}
                            voiceRevenue={subRegionDetails.totalRevenue}
                        />
                    }
                </div>
                <div className={styles.groupTopSection}>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE YTD Revenue​</div>
                        <div className={styles.bodyValue}>{(subRegionDetails.totalRevenue !== undefined) ? '$' + utils.convertToInternationalCurrencySystem(subRegionDetails.totalRevenue.toFixed(2)) : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE YoY Growth​</div>
                        <div className={styles.bodyValue}>{(subRegionDetails.YTDGrowth !== undefined) ? subRegionDetails.YTDGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                    <div className={styles.innerLayout}>
                        <div className={styles.bodyTitle}>VOICE QoQ Growth​</div>
                        <div className={styles.bodyValue}>{(subRegionDetails.QoQGrowth !== undefined) ? subRegionDetails.QoQGrowth.toFixed(2) + '%' : '-'}</div>
                    </div>
                </div>
                <div>
                    <Accordion
                        title="Revenue Distribution by Channel (YTD)"
                        type="customerSegment"
                        list={customerSegment}
                    />
                    {
                        <Accordion
                            title="Revenue Distribution by Order Entry Channel (YTD)"
                            type="OMC"
                            list={omc}
                        />
                    }
                    <Accordion
                        title="Revenue Distribution by Product Division (YTD)"
                        type="productDivision"
                        list={productDivision}
                    />
                    <Accordion title="Open Order & Backorder"
                        type="openOrder"
                        open_backorder={open_backorder}
                    />

                    {//Remove customers Gained/Lost section
                    /*<Accordion title="Customers Gained/Lost"
                        type="customerGained"
                        custGained={custGained}
                    />*/}
                </div>
            </div>
        </div>)

}

export default SubRegionAttribute